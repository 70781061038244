import { ApplicationConfig, importProvidersFrom, isDevMode, provideZoneChangeDetection } from '@angular/core'
import { provideRouter } from '@angular/router'

import { routes } from './app.routes'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { LocationStrategy, PathLocationStrategy } from '@angular/common'
import { appInitializerProviders, httpInterceptorProviders } from '@core'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { FeatherModule } from 'angular-feather'
import { ToastrModule } from 'ngx-toastr'
import { NgProgressHttpModule } from 'ngx-progressbar/http'
import { NgProgressRouterModule } from 'ngx-progressbar/router'
import { allIcons } from 'angular-feather/icons'
import { provideHttpClient } from '@angular/common/http'
import { provideState, provideStore } from '@ngrx/store'
import { provideStoreDevtools } from '@ngrx/store-devtools'

import { provideEffects } from '@ngrx/effects'
import * as profileEffects from './features/profiles/store/effects'
import * as clientEffects from './features/clients/store/effects'
import { BrowserAnimationsModule, provideAnimations, provideNoopAnimations } from '@angular/platform-browser/animations'
import {
    profileCreateFeatureKey,
    profileCreateReducer,
    profileDetailsFeatureKey,
    profileDetailsReducer,
    profileFeatureKey,
    profileReducer,
    profilesFeatureKey,
    profilesReducer,
    profileUpdateFeatureKey,
    profileUpdateReducer,
} from './features/profiles/store/reducers'
import { ProfilesTableService } from './features/profiles/service/profiles-table.service'
import {
    clientCreateReducer,
    clientDetailsFeatureKey,
    clientDetailsReducer,
    clientListReducer,
    clientsCreateFeatureKey,
    clientsListFeatureKey,
    clientUpdateFeatureKey,
    clientUpdateReducer,
} from './features/clients/store/reducer'
import { environment } from '@env/environment.dev'

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(),
        provideAnimations(),
        provideNoopAnimations(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        provideAnimationsAsync(),
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        httpInterceptorProviders,
        appInitializerProviders,

        provideStore(),
        provideState(profileFeatureKey, profileReducer),
        provideState(profilesFeatureKey, profilesReducer),
        provideState(profileCreateFeatureKey, profileCreateReducer),
        provideState(profileUpdateFeatureKey, profileUpdateReducer),
        provideState(profileDetailsFeatureKey, profileDetailsReducer),

        provideState(clientsListFeatureKey, clientListReducer),
        provideState(clientsCreateFeatureKey, clientCreateReducer),
        provideState(clientUpdateFeatureKey, clientUpdateReducer),
        provideState(clientDetailsFeatureKey, clientDetailsReducer),
        provideEffects(profileEffects, clientEffects),
        provideStoreDevtools({
            maxAge: 25,
            logOnly: !isDevMode(),
            trace: false,
            traceLimit: 75,
        }),

        importProvidersFrom(NgProgressHttpModule, NgProgressRouterModule),
        { provide: MAT_DATE_LOCALE, useValue: 'en-ZA' },
        { provide: DateAdapter, useClass: MomentDateAdapter },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD',
                },
                display: {
                    dateInput: 'YYYY-MM-DD',
                    monthYearLabel: 'YYYY MMM',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'YYYY MMM',
                },
            },
        },
        importProvidersFrom(FeatherModule.pick(allIcons)),
        importProvidersFrom(ToastrModule.forRoot()),
        //provideCharts(withDefaultRegisterables()),
    ],
}
